<template>
  <CommonSearchBarSafeArea />
  <div class="h-[calc(100%-16px)] overflow-scroll">
    <GeofenceForm
      ref="formRef"
      :initial-values="initialValues"
      :is-b2b="isB2B"
      @submit="submit"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import GeofenceForm from '@/components/geofence/_common/GeofenceForm.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import { COMMON_SAVE_ERROR, COMMON_SAVE_SUCCESS } from '@/config/constants';
import * as events from '@/events';

const toast = useToast();
const store = useStore();
const router = useRouter();
const route = useRoute();

const isB2B = store.getters['auth/isB2B'];
const id = +route.params.id;
const accountId = store.getters['auth/accountId'];
const geofence = computed(() => store.getters['geofence/byId'](id));
const formRef = ref(null);

onUnmounted(() => {
  store.dispatch('geofence/setViewportObj', null);
  store.dispatch('geofence/setShape', null);
});

const initialValues = {
  ...geofence.value,
  address: {
    label: geofence.value.address,
    lat: 1,
    lng: 1,
  },
  is_alertable: Boolean(geofence.value.is_alertable),
};

events.subscribe(events.names.GEOFENCE_SAVE, () => {
  formRef.value.submit();
});

const submit = (params) => {
  store.commit('geofence/isSaving', true);
  store
    .dispatch('geofence/update', { accountId, id, params })
    .then((res) => {
      router.replace({
        name: 'GeofenceDetailsView',
        params: { id: res.data.data.id },
      });
      events.trigger(events.names.GEOFENCE_CREATED, {
        id: res.data.data.id,
      });
      toast.success(COMMON_SAVE_SUCCESS);
    })
    .catch(() => {
      store.commit('geofence/isSaving', false);
      toast.error(COMMON_SAVE_ERROR);
    });
};
</script>
