<template>
  <div
    class="pl-6 pr-4 sm:w-4/12 sm:border-r sm:border-lgmx_gray-100 md:pl-12 md:pr-6"
  >
    <GeofenceEditNavBar class="h-[64px] sm:h-[48px] sm:translate-y-2" />
  </div>
  <div class="hidden justify-between px-4 py-2 sm:flex sm:w-8/12 md:pr-12">
    <div
      class="flex gap-2"
      @mouseenter="setSaveButtonHoverState(true)"
      @mouseleave="setSaveButtonHoverState(false)"
    >
      <UITooltip
        class="w-full"
        text="Must draw geofence on the map to save"
        :shown="shouldShowSaveHelp"
        :disabled="Boolean(shape)"
      >
        <UIButtonNew
          size="sm"
          variant="main"
          @click="onSaveClick"
          :is-loading="isSaving"
          is-loading-text="Saving..."
          :disabled="!shape"
          :class="[{ 'cursor-help': shouldShowSaveHelp }]"
        >
          Save
        </UIButtonNew>
      </UITooltip>
    </div>
  </div>
</template>

<script>
import GeofenceEditNavBar from './GeofenceEditNavBar.vue';
import { mapGetters } from 'vuex';
import * as events from '@/events';

export default {
  components: { GeofenceEditNavBar },
  data() {
    return {
      isSaveButtonHovered: false,
    };
  },

  computed: {
    ...mapGetters({
      shape: 'geofence/shape',
      isSaving: 'geofence/isSaving',
    }),
    shouldShowSaveHelp() {
      return !this.shape && this.isSaveButtonHovered;
    },
  },

  methods: {
    onSaveClick() {
      events.trigger(events.names.GEOFENCE_SAVE);
    },
    setSaveButtonHoverState(value) {
      if (this.shape) return;
      this.isSaveButtonHovered = value;
    },
  },
};
</script>
