<template>
  <VeeForm ref="formRef">
    <CommonCollapse title="Info" class="mb-1" :initial-open="true">
      <VeeInput
        class="mb-5"
        name="name"
        label="Name"
        placeholder="My House"
        :value="props.initialValues.name"
        :validateFn="validateName"
        :required="!readonly"
        :readonly="props.readonly"
      />
      <VeeGoogleAutocomplete
        v-if="mounted"
        name="address"
        label="Address"
        placeholder="Enter a location"
        :value="props.initialValues.address"
        :validateFn="validateAddress"
        @callback="setViewportObj"
        :required="!readonly"
        :readonly="props.readonly"
      />
    </CommonCollapse>
    <CommonCollapse
      v-if="props.isB2b"
      :initial-open="true"
      title="Allowable Parameters"
      class="mb-1"
    >
      <div class="grid grid-cols-3 gap-y-5">
        <!-- first row -->
        <div class="col-span-2">
          <div class="text-sm font-medium text-lgmx_black-200">
            Max Time in Geofence
          </div>
          <div class="text-[13px] text-lgmx_gray-700">
            Maximum number of days an asset can dwell inside this geofence
            (0-500).
          </div>
        </div>
        <div class="justify-self-end">
          <VeeInput
            type="number"
            class="w-[80px]"
            name="allowable_dwell_days"
            placeholder="10"
            :value="props.initialValues.allowable_dwell_days"
            :validateFn="validateAllowableDwellTime"
            :readonly="props.readonly"
            @wheel="$event.target.blur()"
          />
        </div>
        <!-- second row -->
        <div class="col-span-2">
          <div class="text-sm font-medium text-lgmx_black-200">
            Max Number of Assets
          </div>
          <div class="text-[13px] text-lgmx_gray-700">
            Maximum number of assets allowed inside this geofence at one time
            (0-500).
          </div>
        </div>
        <div class="justify-self-end">
          <VeeInput
            type="number"
            class="w-[80px]"
            name="max_assets"
            placeholder="10"
            :value="props.initialValues.max_assets"
            :validateFn="validateMaxAssets"
            :readonly="props.readonly"
            @wheel="$event.target.blur()"
          />
        </div>
        <!-- third row -->
        <div class="col-span-2">
          <div class="text-sm font-medium text-lgmx_black-200">
            Min Number of Assets
          </div>
          <div class="text-[13px] text-lgmx_gray-700">
            Minimum number of assets allowed inside this geofence at one time
            (0-500).
          </div>
        </div>
        <div class="justify-self-end">
          <VeeInput
            type="number"
            class="w-[80px]"
            name="min_assets"
            placeholder="10"
            :value="props.initialValues.min_assets"
            :validateFn="validateMinContainers"
            :readonly="props.readonly"
            @wheel="$event.target.blur()"
          />
        </div>
      </div>
    </CommonCollapse>
    <CommonCollapse v-if="props.isB2b" title="Contact Details" class="mb-1">
      <VeeInput
        class="mb-5"
        name="full_name"
        label="Name"
        placeholder="Mark"
        :readonly="props.readonly"
        :value="props.initialValues.full_name"
      />
      <VeeInput
        type="email"
        class="mb-5"
        name="email"
        label="Email"
        placeholder="mark@mark.com"
        :value="props.initialValues.email"
        :validateFn="validateEmail"
        :readonly="props.readonly"
      />
      <VeeInput
        class="mb-5"
        name="phone"
        label="Phone Number"
        placeholder="(000) 000-0000"
        :value="props.initialValues.phone"
        data-maska="(###) ###-####"
        :validateFn="validatePhone"
        :readonly="props.readonly"
      />
      <VeeInput
        name="company_name"
        label="Company"
        placeholder="GPX"
        :readonly="props.readonly"
        :value="props.initialValues.company_name"
      />
    </CommonCollapse>
    <CommonCollapse v-if="props.isB2b" title="Notes">
      <VeeTextArea
        name="notes"
        label="Geofence Notes"
        placeholder="Use Geofence Notes for record keeping purposes or to share important details with internal team members about a specific geofence."
        :value="props.initialValues.notes"
        :readonly="props.readonly"
      />
    </CommonCollapse>
    <div class="p-5">
      <div class="mb-6">
        <VeeCheckbox
          name="is_alertable"
          label="Alert on enter/exit"
          size="medium"
          :disabled="props.readonly"
          :initialValue="props.initialValues.is_alertable"
        />
      </div>
      <div class="flex justify-between gap-x-1">
        <slot />
      </div>
    </div>
  </VeeForm>
</template>

<script setup>
import * as _ from 'lodash';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import CommonCollapse from '@/components/_common/CommonCollapse.vue';
import VeeInput from '@/components/vee/VeeInput.vue';
import VeeTextArea from '@/components/vee/VeeTextArea.vue';
import VeeCheckbox from '@/components/vee/VeeCheckbox.vue';
import VeeGoogleAutocomplete from '@/components/vee/VeeGoogleAutocomplete.vue';

const emits = defineEmits(['submit']);
const props = defineProps({
  readonly: {
    type: Boolean,
    default: false,
  },
  initialValues: {
    type: Object,
    default: () => ({}),
  },
  isB2b: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const mounted = ref(false);
const formRef = ref(null);
const shape = computed(() => store.getters['geofence/shape']);

onMounted(() => (mounted.value = true));

function submit() {
  const values = formRef.value.getValues();
  const params = {
    name: values.name,
    address: values.address.label,
    allowable_dwell_days: _.isNil(values.allowable_dwell_days)
      ? null
      : parseInt(values.allowable_dwell_days),
    max_assets: _.isNil(values.max_assets) ? null : parseInt(values.max_assets),
    min_assets: _.isNil(values.min_assets) ? null : parseInt(values.min_assets),
    full_name: values.full_name,
    email: values.email,
    phone: values.phone,
    company_name: values.company_name,
    is_alertable: values.is_alertable ? 1 : 0,
    notes: values.notes || null,
    ...shape.value,
  };
  emits('submit', params);
}

defineExpose({
  submit,
});

const validateName = (value) => (!value ? 'Name is required' : true);
const validateAddress = (value) => {
  if (!value) return 'address is required';
  if (typeof value.label !== 'string') return 'label is not a string';
  if (!value.lat || !value.lng) return 'lat and lng are required';
  return true;
};
const validateAllowableDwellTime = (value) => {
  if (value) {
    if (value < 0) return 'cannot be less than 0';
    if (value > 500) return 'cannot be greater than 500';
  }
  return true;
};
const validateMaxAssets = (value) => {
  if (value) {
    if (value < 0) return 'cannot be less than 0';
    if (value > 500) return 'cannot be greater than 500';
  }
  return true;
};
const validateMinContainers = (value) => {
  if (value) {
    if (value < 0) return 'cannot be less than 0';
    if (value > 500) return 'cannot be greater than 500';
  }
  return true;
};
const validateEmail = (value) => {
  if (value) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(value) ? true : 'not an email';
  }
  return true;
};
const validatePhone = (value) => {
  if (value) {
    return value.length === 14 ? true : 'not valid phone';
  }
  return true;
};

function setViewportObj(fullAddress) {
  const viewport = _.get(fullAddress, 'place.geometry.viewport', null);
  if (viewport) {
    store.dispatch('geofence/setViewportObj', viewport);
  }
}
</script>
